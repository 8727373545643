.layaut{
    margin: 0;
    padding: 0;
}

.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 740px;
    background-color: #F0F2F5;
    /*border: solid 2px red;*/
}
    
.main-card{
    display: flex;
    flex-direction: column;
    width: 362px;
    /*border: solid 1px #0478DF;*/
    justify-content:space-evenly;
    padding-bottom: 100px;

}

.title{
    /*margin-left: 50px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 200px;
    /*border: solid 2px red;*/
    margin: 14px auto;
}

h1{
    display: none;
    color: #0478DF;
    font-size: 54px;
    font-style: italic;
    height: 80px;
    margin: 0;
    padding: 0;
    /*border: solid 2px red;*/
}

.slogan{
    display: flex;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
    font-weight: 200;
    font-style: initial;
    /*font-weight: 500;*/
    color: #1C1E21;
    margin: 20px auto;
    /*width: 400px;*/
}

.email-field{
    width: 324px;
    height: 52;
    /*padding: 14px 16px;*/
    border-radius: 12px;
}

.text-field{
    width: 324px;
    height: 80px;
    border-radius: 12px;
}

.container-form{
    width: 350px;
    box-shadow: 0px 0px 20px #CEDEDE;
    border-radius: 16px;
    margin: 0 auto;
    display: flex;
    height: 360px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: #155D8F;        
}

.principal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media only screen and (min-width: 901px){    
    .main{
        height: 740px;
    }

    .main-card{
        display: flex;
        flex-direction: row;
        height: 660px;
        justify-content: center;
        align-items: center;
        width: 900px;
        /*padding: 72px 0px 162px;*/
        /*border: solid 4px #0478DF;*/
    }
    
    .title{
        display: flex;
        flex-direction: column;
        /*justify-content: left;*/
        align-items: flex-start;
        width: 520px;
        /*border: solid 2px red;*/
        margin-right: 55px;
    }

    .slogan{
        text-align: left;
        font-size: 26px;
        margin: 6 auto;
    }


    h1{
        display:flex;
        color: #0478DF;
        font-size: 62px;
        font-style: italic;
        height: 80px;
        margin: 0;
        padding: 0;
        /*border: solid 2px red;*/
    }

    .container-form{
        height: 360px;
        width: 370px;
        display: flex;
        flex-direction: column;
        background-color: white;
        justify-content: space-around;
        /*border: 2px solid red;*/
    }

    .text-field{
        width: 324px;
        height: 80px;
        border-radius: 12px;
    }
     
}